//
// == Deps
//

@import 'bower_components/bootstrap/less/bootstrap';
@import 'variables';
@import 'bootswatch';

//
// == Custom
//

.table {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                vertical-align: middle;
            }
        }
    }
}
